import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { CSVLink, CSVDownload } from "react-csv";
import { searchChildrenAPI } from "../../service/UserService";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { getUserBetsAPI } from "../../service/AuthService";
import { usePDF } from "react-to-pdf";
import ExportCSV from "../Game/components/ExportToCsv";
import {
  getAllEventsAPI,
  getCompetitionAPI,
  updateCompetitionByIdAPI,
  updateEventByIdAPI,
} from "../../service/competition";
import { Switch } from "@chakra-ui/react";
export default function ListCompetition() {
  const [size, setSize] = useState(20);
  const { competitionId, sport } = useParams();
  const [allCompetition, setAllCompetition] = useState([{}]);
  const { toPDF, targetRef } = usePDF({ filename: "events.pdf" });
  const [currentTab, setCurrentTab] = useState("football");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getAllCompetition = async () => {
    const data = await getCompetitionAPI();
    setAllCompetition(data);
  };
  const getAllCompetitionEvents = async () => {
    const data = await getAllEventsAPI(competitionId, sport);
    setAllCompetition(data);
  };
  useEffect(() => {
    if (competitionId && sport) {
      getAllCompetitionEvents();
    } else {
      getAllCompetition();
    }
  }, [sport, competitionId]);

  const handleSearch = async (e) => {
    let searchText = e.target.value;

    e.preventDefault();
    if (searchText == "" || searchText.length < 3) {
      if (sport) {
        getAllCompetitionEvents();
      } else {
        getAllCompetition();
      }
      return;
    } else {
      let data = [...allCompetition];
      data?.filter((item) => {
        if (sport) {
          if (item?.event?.name?.toLowerCase().includes(searchText)) {
            return true;
          }
        } else {
          if (item?.competitionName?.toLowerCase().includes(searchText)) {
            return true;
          }
        }
      });
      setAllCompetition(data);
    }
  };
  const handleSwitchChange = async (data) => {
    if (sport) {
      const response = await updateEventByIdAPI(
        data?.eventId,
        !data.isEnabled,
        data?.sport
      );
      if (response.isEnabled == !data.isEnabled) {
        toast.success("Competition updated successfully");
        getAllCompetitionEvents();
      } else {
        toast.error("Failed to update competition status");
      }
    } else {
      const response = await updateCompetitionByIdAPI(
        data?.competitionId,
        !data.isEnabled,
        data?.sport
      );
      if (response.isEnabled == !data.isEnabled) {
        toast.success("Competition updated successfully");
        getAllCompetition();
      } else {
        toast.error("Failed to update competition status");
      }
    }
  };

  return (
    <div className="main">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 main-container">
            <div className="listing-grid">
              <div className="detail-row">
                <div className="row">
                  <div className="col-md-6 m-b-10">
                    <h2 className="d-inline-block">
                      {sport?.toUpperCase() || "ALL COMPETITION"}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <div
                  id="clientListTable_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <div className="dt-buttons">
                    {" "}
                    <button
                      className="dt-button buttons-pdf buttons-html5"
                      tabIndex={0}
                      aria-controls="clientListTable"
                      type="button"
                      onClick={toPDF}
                    >
                      <span>PDF</span>
                    </button>{" "}
                    <ExportCSV data={allCompetition} />
                  </div>
                  <div
                    id="clientListTable_filter"
                    className="dataTables_filter"
                  >
                    <label>
                      Search:
                      <input
                        type="search"
                        className="form-control"
                        placeholder
                        aria-controls="clientListTable"
                        onChange={handleSearch}
                      />
                    </label>
                  </div>

                  {!sport && (
                    <ul
                      role="tablist"
                      className="nav nav-tabs"
                      id="__BVID__111__BV_tab_controls_"
                    >
                      {/**/}
                      <li role="presentation" className="nav-item">
                        <a
                          role="tab"
                          aria-selected="true"
                          aria-setsize={2}
                          aria-posinset={1}
                          onClick={() => setCurrentTab("cricket")}
                          target="_self"
                          className={`nav-link ${
                            currentTab == "cricket"
                              ? "active tab-bg-warning"
                              : ""
                          }`}
                          id="__BVID__112___BV_tab_button__"
                          aria-controls="__BVID__112"
                        >
                          Cricket
                        </a>
                      </li>
                      <li role="presentation" className="nav-item">
                        <a
                          role="tab"
                          tabIndex={-1}
                          aria-selected="false"
                          aria-setsize={2}
                          aria-posinset={2}
                          onClick={() => setCurrentTab("football")}
                          target="_self"
                          className={`nav-link ${
                            currentTab == "football"
                              ? "active tab-bg-warning"
                              : ""
                          }`}
                          id="__BVID__114___BV_tab_button__"
                          aria-controls="__BVID__114"
                        >
                          Football
                        </a>
                      </li>
                      <li role="presentation" className="nav-item">
                        <a
                          role="tab"
                          tabIndex={-1}
                          aria-selected="false"
                          aria-setsize={2}
                          aria-posinset={2}
                          onClick={() => setCurrentTab("tennis")}
                          target="_self"
                          className={`nav-link ${
                            currentTab == "tennis"
                              ? "active tab-bg-warning"
                              : ""
                          }`}
                          id="__BVID__114___BV_tab_button__"
                          aria-controls="__BVID__114"
                        >
                          Tennis
                        </a>
                      </li>
                    </ul>
                  )}

                  <table
                    ref={targetRef}
                    id="clientListTable"
                    className="table table-striped  table-bordered  no-footer dataTable"
                    style={{ width: "100%" }}
                    role="grid"
                    aria-describedby="clientListTable_info"
                  >
                    <thead>
                      <tr role="row">
                        {allCompetition?.length > 0 &&
                          Object.keys(allCompetition[0]).map((header) => (
                            <th
                              className="sorting_asc sorting_disabled"
                              tabIndex={0}
                              aria-controls="clientListTable"
                              rowSpan={1}
                              colSpan={1}
                              aria-sort="ascending"
                              aria-label="User Name: activate to sort column descending"
                            >
                              {header?.toUpperCase()}
                            </th>
                          ))}
                      </tr>
                    </thead>
                    <tbody id="manage_user_tbody">
                      {allCompetition.length == 0 ? (
                        <td colSpan={12}>No data found</td>
                      ) : (
                        allCompetition
                          ?.filter(
                            (client) =>
                              client?.sport?.toLowerCase() ==
                              currentTab?.toLowerCase()
                          )
                          ?.map((client) => (
                            <tr
                              role="row"
                              className="odd"
                              key={client.id}
                              style={{ backgroundColor: "white" }}
                            >
                              {Object.keys(client).map((key) => (
                                <td>
                                  {key == "id" && !sport ? (
                                    <Link
                                      to={`/admin/all-competition/${client.sport}/${client?.competitionId}`}
                                    >
                                      {client[key]}
                                    </Link>
                                  ) : typeof client[key] == "object" ? (
                                    client[key] &&
                                    Object.keys(client[key]).map((innerKey) => (
                                      <div>
                                        {innerKey?.toUpperCase()} :{" "}
                                        {client[key][innerKey]}
                                      </div>
                                    ))
                                  ) : typeof client[key] == "string" ||
                                    typeof client[key] == "number" ? (
                                    client[key]
                                  ) : typeof client[key] == "boolean" ? (
                                    <Switch
                                      onChange={() =>
                                        handleSwitchChange(client)
                                      }
                                      size="sm"
                                      isChecked={client[key]}
                                    />
                                  ) : null}
                                </td>
                              ))}
                            </tr>
                          ))
                      )}
                    </tbody>
                  </table>

                  {/*  <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="clientListTable_paginate"
                  >
                    <a
                      className="previous  button btn btn-diamond"
                      aria-controls="clientListTable"
                      data-dt-idx={0}
                      tabIndex={-1}
                      id="clientListTable_previous"
                     
                    >
                      Previous
                    </a>
                    
                    <a
                      className="next  button btn btn-diamond"
                      aria-controls="clientListTable"
                      data-dt-idx={2}
                      tabIndex={-1}
                      id="clientListTable_next"
                      
                    >
                      Next
                    </a>
                  </div> */}
                </div>
              </div>
              <div className="row m-t-10 m-b-10">
                <div className="col-md-6"></div>
                <div className="col-md-6" id="pagination"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
