import { AXIOS_INSTANCE } from "..";
import { BASE_URL } from "../../utils/constants";

export const getCompetitionAPI = async () => {
  try {
    const { data } = await AXIOS_INSTANCE.get(`${BASE_URL}/competition/all`);
    return data;
  } catch (error) {
    return error;
  }
};
export const updateCompetitionByIdAPI = async (id, isActive, sport) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${BASE_URL}/competition/update?competitionId=${id}&isActive=${isActive}&sport=${sport}`
    );

    return data;
  } catch (error) {
    return error;
  }
};
export const getAllEventsAPI = async (id, sport) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/event/all?competitionId=${id}&sport=${sport}`
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const updateEventByIdAPI = async (id, isActive, sport) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${BASE_URL}/event/update?eventId=${id}&isActive=${isActive}&sport=${sport}`
    );

    return data;
  } catch (error) {
    return error;
  }
};
export const getDiamondResultAPI = async (id) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/event/diamondResult?gameId=${id}`
    );
    return data;
  } catch (error) {
    return error;
  }
};
