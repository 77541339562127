import React, { useEffect, useState } from "react";
import {
  getAllEventsAPI,
  getCompetitionAPI,
  getDiamondResultAPI,
} from "../service/competition";
import Select from "react-select";
import { Spinner, Switch } from "@chakra-ui/react";

export default function ResultPage() {
  const [results, setResults] = React.useState([{}]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [allCompetitions, setAllCompetitions] = useState([]);
  const [allEventsForCompetition, setAllEventsForCompetition] = useState([]);
  const [selectedCompetition, setSelectedCompetition] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedSport, setSelectedSport] = useState(null);
  const [selectedMarket, setSelectedMarket] = useState(null);
  const [selectedNat, setSelectedNat] = useState(null);
  const getAllCompetition = async () => {
    const data = await getCompetitionAPI();
    setAllCompetitions(data);
  };
  const getAllCompetitionEvents = async (competitionId, sport) => {
    const data = await getAllEventsAPI(competitionId, sport);
    setAllEventsForCompetition(data);
  };
  const processAllCompetitions = async (data) => {
    setIsLoading(true);

    setIsLoading(false);
  };
  const getDiamondResult = async (id) => {
    const data = await getDiamondResultAPI(id);
    return data;
  };
  useEffect(() => {
    getAllCompetition();
  }, [selectedSport]);
  useEffect(() => {
    if (selectedCompetition) {
      getAllCompetitionEvents(
        selectedCompetition.value,
        selectedCompetition.sport
      );
    }
  }, [selectedCompetition]);
  useEffect(() => {
    if (selectedEvent) {
      getDiamondResult(selectedEvent.eventId).then((data) => {
        setResults(data);
      });
    }
  }, [selectedEvent]);
  return (
    <div className="main">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 main-container">
            <div className="listing-grid">
              <div
                style={{
                  height: "100vh",
                }}
                className="table-responsive"
              >
                {isLoading && <Spinner />}
                <div className="col-md-12 ">
                  <label>SPORT</label>
                  <Select
                    options={[
                      { value: "CRICKET", label: "CRICKET" },
                      { value: "FOOTBALL", label: "FOOTBALL" },
                      { value: "TENNIS", label: "TENNIS" },
                    ]}
                    onChange={(selectedOption) =>
                      setSelectedSport(selectedOption)
                    }
                    value={selectedSport}
                  />
                </div>
                <div className="col-md-12 ">
                  <label>Competition</label>
                  <Select
                    options={allCompetitions
                      ?.filter((item) => item?.sport === selectedSport?.value)
                      ?.map((item) => {
                        return {
                          ...item,
                          label: item?.competitionName,
                          value: item?.competitionId,
                        };
                      })}
                    onChange={(selectedOption) =>
                      setSelectedCompetition(selectedOption)
                    }
                    value={selectedCompetition}
                  />
                </div>
                <div className="col-md-12 ">
                  <label>Event</label>
                  <Select
                    options={allEventsForCompetition?.map((item) => {
                      return {
                        ...item,
                        label: item?.event?.name,
                        value: item?.eventId,
                      };
                    })}
                    onChange={(selectedOption) =>
                      setSelectedEvent(selectedOption)
                    }
                    value={selectedEvent}
                  />
                </div>
                <div className="col-md-12 ">
                  <label>Search by market name</label>
                  <Select
                    options={results?.map((item) => {
                      return {
                        ...item,
                        label: item?.market_name,
                        value: item?.gameId,
                      };
                    })}
                    value={selectedMarket}
                    onChange={(selectedOption) => {
                      setSelectedMarket(selectedOption);
                    }}
                  />
                </div>
                <div className="col-md-12 ">
                  <label>Search by NAT</label>
                  <Select
                    options={results?.map((item) => {
                      return {
                        ...item,
                        label: item?.nat,
                        value: item?.gameId,
                      };
                    })}
                    value={selectedNat}
                    onChange={(selectedOption) => {
                      setSelectedNat(selectedOption);
                    }}
                  />
                </div>
                <div
                  id="clientListTable_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <table
                    id="clientListTable"
                    className="table table-striped  table-bordered  no-footer dataTable"
                    style={{ width: "100%" }}
                    role="grid"
                    aria-describedby="clientListTable_info"
                  >
                    <thead>
                      <tr role="row">
                        {results?.length > 0 &&
                          Object.keys(results[0]).map((header) => (
                            <th
                              className="sorting_asc sorting_disabled"
                              tabIndex={0}
                              aria-controls="clientListTable"
                              rowSpan={1}
                              colSpan={1}
                              aria-sort="ascending"
                              aria-label="User Name: activate to sort column descending"
                            >
                              {header?.toUpperCase()}
                            </th>
                          ))}
                      </tr>
                    </thead>
                    <tbody id="manage_user_tbody">
                      {results.length == 0 ? (
                        <td colSpan={12}>No data found</td>
                      ) : (
                        results
                          ?.filter((item) => {
                            if (selectedMarket && selectedNat) {
                              return (
                                item?.market_name === selectedMarket?.label &&
                                item?.nat === selectedNat?.label
                              );
                            } else if (selectedMarket) {
                              return (
                                item?.market_name === selectedMarket?.label
                              );
                            } else if (selectedNat) {
                              return item?.nat === selectedNat?.label;
                            }
                            return true;
                          })
                          ?.map((client) => (
                            <tr
                              role="row"
                              className="odd"
                              style={{ backgroundColor: "white" }}
                            >
                              {Object.values(client).map((value) => (
                                <td>{value}</td>
                              ))}
                            </tr>
                          ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
